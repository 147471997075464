<template>
  <b-form
    id="dairy-add-form"
    class=""
    @submit.prevent="handleSubmit(onSubmit)"
    @reset.prevent="resetForm"
  >
    <!-- rationname -->
    <validation-provider
      #default="validationContext"
      rules="required"
      name="rationname"
    >
      <b-form-group :label="$t('general')" class="p-1 border">
        <label for="rationname">
          <span class="text-danger">*</span>
          {{ $t("rationName") }}
        </label>
        <b-form-input
          id="rationname"
          v-model="blankRationData.rationname"
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          trim
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>

    <!-- status -->

    <b-form-group
      id="animal-info"
      class="p-1 border d-flex flex-sm-row flex-md-col"
      :label="$t('animalInfo')"
    >
      <validation-provider #default="validationContext" name="breed">
        <label for="breed">
          <span class="text-danger">*</span>
          {{ $t("breed") }}
        </label>
        <v-select
          id="breed"
          v-model="blankRationData.irkid"
          transition=""
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="animalTypes"
          class="w-100"
          :reduce="(val) => val.value"
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>

      <!-- ca -->
      <validation-provider #default="validationContext" name="ca">
        <label for="ca">
          <span class="text-danger">*</span>
          {{ $t("bbca") }}
        </label>
        <b-form-input
          id="ca"
          v-model="blankRationData.CA"
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          type="number"
          trim
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>

      <!-- gcaa -->
      <validation-provider #default="validationContext" name="gcaa">
        <label for="gcaa">
          <span class="text-danger">*</span>
          {{ $t("agcaa") }} (Gram)
        </label>
        <b-form-input
          id="gcaa"
          v-model="blankRationData.GCAA"
          placeholder="0.1"
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          trim
          type="number"
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>

      <!-- eca -->
      <validation-provider #default="validationContext" name="eca">
        <label for="eca">
          <span class="text-danger">*</span>
          {{ $t("eca") }} (KG)
        </label>
        <b-form-input
          id="eca"
          v-model="blankRationData.ECA"
          placeholder="300"
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          trim
          type="number"
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>

    <b-form-group
      id="environmental-info"
      class="p-1 border d-flex flex-sm-row flex-md-col"
      :label="$t('environmentalInfo')"
    >
      <!-- sicaklik -->
      <validation-provider #default="validationContext" name="sicaklik">
        <label for="sicaklik">
          <span class="text-danger">*</span>
          {{ $t("temperature") }}
        </label>
        <b-form-input
          id="GY"
          v-model="blankRationData.sicaklik"
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          trim
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>

    <b-form-group
      id="min-max-info"
      class="p-1 border d-flex flex-sm-row flex-md-col"
      :label="$t('roughage') + ' / ' + $t('grain') + ' ' + $t('rate') + ' (%)'"
    >
      <validation-provider #default="validationContext" name="minkaba">
        <b-form-group
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          :label="$t('minKaba')"
          label-for="minkaba"
        >
          <b-form-input
            id="sl"
            v-model="blankRationData.kabayemorani"
            :state="
              formValidation(resetblankration).getValidationState(
                validationContext
              )
            "
            trim
            type="number"
            placeholder=""
          />
        </b-form-group>
      </validation-provider>

      <validation-provider #default="validationContext" name="maxkaba">
        <b-form-group
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          :label="$t('maxKaba')"
          label-for="maxkaba"
        >
          <b-form-input
            id="maxkaba"
            v-model="blankRationData.kabayemoranimax"
            :state="
              formValidation(resetblankration).getValidationState(
                validationContext
              )
            "
            trim
            type="number"
            max="100"
            placeholder=""
          />
        </b-form-group>
      </validation-provider>
    </b-form-group>
  </b-form>
</template>

<script>
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import {
  BForm,
  BFormGroup,
  BFormInput,
  VBToggle,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider } from "vee-validate";
import dayjs from "dayjs";
import { getUserData } from "@/auth/utils";
export default {
  components: {
    BForm,
    BFormInput,
    vSelect,
    ValidationProvider,
    BFormGroup,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  props: {
    handleSubmit: {
      type: Function,
      required: true,
    },
    hide: {
      type: Function,
      required: true,
    },
    formValidation: {
      type: Function,
      required: true,
    },
    animalTypes: {
      type: Array,
      required: true,
    },
  },
  // Yunus Emre Çağlıyan Yunus Emre Çağlıyan Yunus Emre Çağlıyan
  data() {
    return {
      oneToFive: Array.from(Array(5), (_, i) => i + 1).map((val) => {
        return { label: val, value: val };
      }),
      oneToFiveFloatHalf: Array.from(Array(11), (_, i) => i + 1).map((val) => {
        return { label: val / 2 - 0.5, value: val / 2 - 0.5 };
      }),
      oneToHundred: Array.from(Array(100), (_, i) => i + 1).map((val) => {
        return { label: `${val} / ${100 - val}`, value: val };
      }),
      animalStatuses: [
        { label: this.$t("inLactation"), value: 0 },
        { label: this.$t("inDry"), value: 1 },
        { label: this.$t("heifer"), value: 2 },
      ],
      zeminOptions: [
        { label: this.$t("closed"), value: 0 },
        { label: this.$t("softGround"), value: 1 },
        { label: this.$t("roughGround"), value: 2 },
      ],
      blankRationData: {
        plantid: this.$store.state.app.selectedPlantId,
        tarih: dayjs().format("YYYY-MM-DD"),
        formuladate: dayjs().format("YYYY-MM-DD"),
        companyid: getUserData().companyid,
        model: "",
        rationname: "",
        kabayemorani: "",
        kabayemoranimax: "",
        hayvantipi: "",
        hayvantipiid: 0,
        breed: "",
        irkid: "",
        hayvanadedi: "",
        CA: "",
        ECA: "",
        GCAA: "",
        sicaklik: "",
        SY: "",
        SV: "",
        SP: "",
      },
      resetblankration: {
        plantid: this.$store.state.app.selectedPlantId,
        tarih: dayjs().format("YYYY-MM-DD"),
        formuladate: dayjs().format("YYYY-MM-DD"),
        companyid: getUserData().companyid,
        model: "",
        rationname: "",
        kabayemorani: "",
        kabayemoranimax: "",
        hayvantipi: "",
        hayvantipiid: 0,
        breed: "",
        irkid: "",
        hayvanadedi: "",
        CA: "",
        ECA: "",
        GCAA: "",
        sicaklik: "",
        SY: "",
        SV: "",
        SP: "",
      },
    };
  },
  computed: {
    realCa() {
      const { SV, CA, GCAA } = this.blankRationData;
      const calculation = ((SV * GCAA) / 1000 + CA * 2) / 2;
      return calculation;
    },
    status() {
      return this.blankRationData.hayvantipiid;
    },
  },
  watch: {
    status: {
      handler: function (newVal, oldVal) {
        if (newVal != oldVal) {
          var name = this.blankRationData.rationname;
          var breed = this.blankRationData.breed;
          var irkid = this.blankRationData.irkid;
          var ca = this.blankRationData.CA;
          var kabayemorani = this.blankRationData.kabayemorani;
          var kabayemoranimax = this.blankRationData.kabayemoranimax;
          this.blankRationData = {
            ...this.resetblankration,
            rationname: name,
            breed: breed,
            irkid: irkid,
            hayvantipiid: newVal,
            CA: ca,
            kabayemorani,
            kabayemoranimax,
          };
        }
      },
      deep: true,
    },
    blankRationData: {
      handler: function (newVal, oldVal) {
        const newmin = Number(this.blankRationData.kabayemorani);
        const newmax = Number(this.blankRationData.kabayemoranimax);

        if (newmin > newmax) {
          this.blankRationData.kabayemoranimax = newmin;
        }
        if (newmin >= 100) {
          this.blankRationData.kabayemorani = 100;
        }
        if (newmax >= 100) {
          this.blankRationData.kabayemoranimax = "100";
        }
        if (newmin < 0) {
          this.blankRationData.kabayemorani = 0;
        }
        if (newmax < 0) {
          this.blankRationData.kabayemoranimax = 0;
        }
      },
      deep: true,
    },
  },

  methods: {
    onSubmit() {
      this.blankRationData.rasyontipi = 2;
      this.blankRationData.breed = this.animalTypes.find(
        (type) => type.value == this.blankRationData.irkid
      ).label;
      Object.keys(this.blankRationData).forEach((key) => {
        if (this.blankRationData[key] === "") {
          this.blankRationData[key] = 0;
        }
      });
      this.$emit("onSubmit", this.blankRationData);
    },
    showField(...ids) {
      return ids.includes(this.status);
    },
  },
};
</script>

<style lang="scss">
.form-group > div {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }

  margin-bottom: 0.5rem;
  width: 100%;
  gap: 14px;
  flex-wrap: wrap;
  span {
    flex: 1;
    min-width: 100%;
    max-width: 100%;
    @media screen and (min-width: 768px) {
      min-width: 40%;
      max-width: 50%;
    }
  }
}

#dairy-add-form {
  .form-group {
    margin-bottom: 0.5rem;
    border-radius: 6px;
    legend {
      width: auto !important;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
</style>
