import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import dayjs from "dayjs";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueI18n from "@/libs/i18n";

export default function useRationsList() {
  // Use toast
  const toast = useToast();
  const refRationListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "preview", label: VueI18n.t("view") },

    { key: "rationname", label: VueI18n.t("rationName"), sortable: false },
    {
      key: "model",
      label: VueI18n.t("rationModel"),
      sortable: true,
    },
    {
      key: "tip",
      label: VueI18n.t("type"),
      sortable: true,
    },
    { key: "delete", label: VueI18n.t("delete") },
  ];
  const perPage = ref(5);
  const totalRations = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refRationListTable.value
      ? refRationListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRations.value,
    };
  });

  const refetchData = () => {
    refRationListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData();
  });

  const fetchRations = async (ctx, callback) => {
    var rations = await store
      .dispatch("rationsModule/fetchRations", {
        queryParams: {
          q: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          status: statusFilter.value,
        },
        tur: router.currentRoute.params.tur,
        plantid: store.state.app.selectedPlantId,
      })

      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", { value: VueI18n.t("rations") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

    const rationList = rations.data;

    totalRations.value = rationList.totalCount;

    return rationList;
  };
  const deleteRation = async (ctx) => {
    await store.dispatch("rationsModule/deleteRation", {
      rationid: ctx.id,
      plantid: ctx.plantid,
      tur: ctx.tur,
    });
    refetchData();
  };
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchRations,
    tableColumns,
    perPage,
    currentPage,
    totalRations,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refRationListTable,
    deleteRation,
    statusFilter,

    refetchData,
  };
}
