var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BModal',{attrs:{"id":"add-new-ration-modal","centered":"","visible":_vm.isAddNewRationModalActive,"bg-variant":"white","shadow":"","backdrop":"","no-header":"","size":"lg","ok-title":_vm.$t('ok'),"title":_vm.$t('addRation'),"ok-only":""},on:{"ok":function($event){return _vm.$refs.addForm.onSubmit()},"hidden":function($event){_vm.formValidation().resetForm},"change":function (val) { return _vm.change(val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('b-form-group',{staticClass:"pb-0",attrs:{"label":_vm.$t('rationType'),"label-for":"rationTypeSelector"}},[(_vm.selectedRationType)?_c('v-select',{staticClass:"w-100",attrs:{"id":"rationTypeSelector","transition":"","disabled":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.rationTypes,"reduce":function (val) { return val.value; }},model:{value:(_vm.selectedRationType),callback:function ($$v) {_vm.selectedRationType=$$v},expression:"selectedRationType"}}):_vm._e()],1),_c('validation-observer',{ref:"formValidation(\n    resetblankration\n  ).refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c(_vm.selectedRationType.rationComponent,{ref:"addForm",tag:"component",attrs:{"handle-submit":handleSubmit,"hide":hide,"form-validation":_vm.formValidation,"animal-types":_vm.selectedRationType.rationModel == 1
            ? _vm.animalTypeNASEM
            : _vm.selectedRationType.rationType == 'Beef'
            ? _vm.animalTypeINRA
            : _vm.animalTypeINRA},on:{"onSubmit":_vm.onSubmit}})]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }