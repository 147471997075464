<template>
  <BModal
    id="add-new-ration-modal"
    centered
    :visible="isAddNewRationModalActive"
    bg-variant="white"
    shadow
    backdrop
    no-header
    size="lg"
    :ok-title="$t('ok')"
    :title="$t('addRation')"
    ok-only
    @ok="$refs.addForm.onSubmit()"
    @hidden="formValidation().resetForm"
    @change="(val) => change(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->

      <b-form-group
        class="pb-0"
        :label="$t('rationType')"
        label-for="rationTypeSelector"
      >
        <v-select
          v-if="selectedRationType"
          id="rationTypeSelector"
          v-model="selectedRationType"
          transition=""
          disabled
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="rationTypes"
          class="w-100"
          :reduce="(val) => val.value"
        />
      </b-form-group>
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="formValidation(
      resetblankration
    ).refFormObserver"
      >
        <component
          :is="selectedRationType.rationComponent"
          ref="addForm"
          :handle-submit="handleSubmit"
          :hide="hide"
          :form-validation="formValidation"
          :animal-types="
            selectedRationType.rationModel == 1
              ? animalTypeNASEM
              : selectedRationType.rationType == 'Beef'
              ? animalTypeINRA
              : animalTypeINRA
          "
          @onSubmit="onSubmit"
        />
        <!-- Form -->
      </validation-observer>
    </template>
  </BModal>
</template>

<script>
import { BFormGroup, BModal } from "bootstrap-vue";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import { ValidationObserver } from "vee-validate";
import { mapState } from "vuex";

import DairyINRA from "../RationAddForm/DairyINRA.vue";
import BeefINRA from "../RationAddForm/BeefINRA.vue";
import DairyNASEM from "../RationAddForm/DairyNASEM.vue";
import BeefNASEM from "../RationAddForm/BeefNASEM.vue";
export default {
  components: {
    BModal,
    BFormGroup,
    ValidationObserver,
    vSelect,

    DairyINRA,
    BeefINRA,
    DairyNASEM,
    BeefNASEM,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewRationModalActive",
    event: "update:is-add-new-ration-modal-active",
  },
  props: {
    isAddNewRationModalActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedRationType: {
        label: "Dairy NASEM",
        value: {
          rationComponent: "DairyNASEM",
          rationPostMethod: "rationsModule/postDairyNASEM",
          rationModel: 1,
          rationType: "Dairy",
        },
      },
      rationTypes: [],
      beefRationTypes: [
        {
          label: this.$t("beef") + " NASEM",
          value: {
            rationComponent: "BeefNASEM",
            rationPostMethod: "rationsModule/postBeefNASEM",
            rationModel: 1,
            rationType: "Beef",
          },
        },
        {
          label: this.$t("beef") + " INRA",
          value: {
            rationComponent: "BeefINRA",
            rationPostMethod: "rationsModule/postBeefINRA",
            rationModel: 2,
            rationType: "Beef",
          },
        },
      ],
      dairyRationTypes: [
        {
          label: this.$t("dairy") + " NASEM",
          value: {
            rationComponent: "DairyNASEM",
            rationPostMethod: "rationsModule/postDairyNASEM",
            rationModel: 1,
            rationType: "Dairy",
          },
        },
        {
          label: this.$t("dairy") + " INRA",
          value: {
            rationComponent: "DairyINRA",
            rationPostMethod: "rationsModule/postDairyINRA",
            rationModel: 2,
            rationType: "Dairy",
          },
        },
      ],
      animalTypeINRA: [
        {
          value: 1,
          label: "Yerli Irk (Salers + Aubracs)",
        },
        {
          value: 2,
          label: "Şarole (Charolais)",
        },
        {
          value: 3,
          label: "Limuzin Erkek (Limousins)",
        },
        {
          value: 4,
          label: "Sütçü Irk Erkek",
        },
        {
          value: 5,
          label: "Etçi Irk Düve",
        },
        {
          value: 6,
          label: "Sütçü Irk Düve",
        },
      ],
      animalTypeINRABeef: [
        {
          value: 1,
          label: "Yerli Irk (Salers + Aubracs)",
        },
        {
          value: 2,
          label: "Şarole (Charolais)",
        },
        {
          value: 3,
          label: "Limuzin Erkek (Limousins)",
        },
        {
          value: 4,
          label: "Sütçü Irk Erkek",
        },
        {
          value: 5,
          label: "Etçi Irk Düve",
        },
        {
          value: 6,
          label: "Sütçü Irk Düve",
        },
      ],
      animalTypeNASEM: [
        {
          value: 1,
          label: "Holştayn",
        },
        {
          value: 2,
          label: "Montofon",
        },
        {
          value: 3,
          label: "Simental",
        },
        {
          value: 4,
          label: "Jersey",
        },
        {
          value: 5,
          label: "Melez",
        },
        {
          value: 6,
          label: "Diğer",
        },
        {
          value: 7,
          label: "Montbeliard",
        },
        {
          value: 8,
          label: "Yerli Kara",
        },
        {
          value: 9,
          label: "Brangus",
        },
        {
          value: 10,
          label: "Güney Anadolu Kırmızısı",
        },
        {
          value: 11,
          label: "Doğu Anadolu Kırmızısı",
        },
        {
          value: 12,
          label: "Boz",
        },
        {
          value: 13,
          label: "Şarole",
        },
        {
          value: 14,
          label: "Limuzin",
        },
        {
          value: 15,
          label: "Blond Aquitaine",
        },
        {
          value: 16,
          label: "Belçika Mavisi",
        },
      ],
      required,
      blankRationData: {},
      oran: true,
      formValidation: formValidation,
      products: [],
    };
  },
  computed: {
    ...mapState({
      model: (state) => state.app.selectedModelId,
    }),
  },
  watch: {
    $route(to, from) {
      this.initValues(to);
    },
    model() {
      this.initValues(this.$route);
    },
  },
  async mounted() {
    this.initValues(this.$route);
  },
  methods: {
    initValues(to) {
      this.rationTypes =
        to.params.tur == "dairy" ? this.dairyRationTypes : this.beefRationTypes;

      this.selectedRationType =
        to.params.tur == "beef" && this.model == 1
          ? {
              rationComponent: "BeefNASEM",
              rationPostMethod: "rationsModule/postBeefNASEM",
              rationModel: 1,
              rationType: "Beef",
            }
          : to.params.tur == "dairy" && this.model == 1
          ? {
              rationComponent: "DairyNASEM",
              rationPostMethod: "rationsModule/postDairyNASEM",
              rationModel: 1,
              rationType: "Dairy",
            }
          : to.params.tur == "beef" && this.model == 2
          ? {
              rationComponent: "BeefINRA",
              rationPostMethod: "rationsModule/postBeefINRA",
              rationModel: 2,
              rationType: "Beef",
            }
          : {
              rationComponent: "DairyINRA",
              rationPostMethod: "rationsModule/postDairyINRA",
              rationModel: 2,
              rationType: "Dairy",
            };
    },
    onSubmit(value) {
      value.model = this.selectedRationType.rationModel;
      value.tip = this.selectedRationType.rationType;
      store
        .dispatch(this.selectedRationType.rationPostMethod, {
          ration: value,
          plantid: this.$store.state.app.selectedPlantId,
        })
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-add-new-ration-modal-active", false);
        });
    },
    change(val) {
      this.$emit("update:is-add-new-ration-modal-active", val);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-ration-modal {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
