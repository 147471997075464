var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{attrs:{"id":"dairy-add-form"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('validation-provider',{attrs:{"rules":"required","name":"rationname"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{staticClass:"p-1 border",attrs:{"label":_vm.$t('general')}},[_c('label',{attrs:{"for":"rationname"}},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("rationName"))+" ")]),_c('b-form-input',{attrs:{"id":"rationname","state":_vm.formValidation(_vm.resetblankration).getValidationState(
            validationContext
          ),"trim":"","placeholder":""},model:{value:(_vm.blankRationData.rationname),callback:function ($$v) {_vm.$set(_vm.blankRationData, "rationname", $$v)},expression:"blankRationData.rationname"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}])}),_c('b-form-group',{staticClass:"p-1 border d-flex flex-sm-row flex-md-col",attrs:{"id":"animal-info","label":_vm.$t('animalInfo')}},[_c('validation-provider',{attrs:{"name":"breed"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',{attrs:{"for":"breed"}},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("breed"))+" ")]),_c('v-select',{staticClass:"w-100",attrs:{"id":"breed","transition":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.animalTypes,"reduce":function (val) { return val.value; }},model:{value:(_vm.blankRationData.irkid),callback:function ($$v) {_vm.$set(_vm.blankRationData, "irkid", $$v)},expression:"blankRationData.irkid"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}])}),_c('validation-provider',{attrs:{"name":"ca"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',{attrs:{"for":"ca"}},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("bbca"))+" ")]),_c('b-form-input',{attrs:{"id":"ca","state":_vm.formValidation(_vm.resetblankration).getValidationState(
            validationContext
          ),"type":"number","trim":"","placeholder":""},model:{value:(_vm.blankRationData.CA),callback:function ($$v) {_vm.$set(_vm.blankRationData, "CA", $$v)},expression:"blankRationData.CA"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}])}),_c('validation-provider',{attrs:{"name":"gcaa"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',{attrs:{"for":"gcaa"}},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("agcaa"))+" (Gram) ")]),_c('b-form-input',{attrs:{"id":"gcaa","placeholder":"0.1","state":_vm.formValidation(_vm.resetblankration).getValidationState(
            validationContext
          ),"trim":"","type":"number"},model:{value:(_vm.blankRationData.GCAA),callback:function ($$v) {_vm.$set(_vm.blankRationData, "GCAA", $$v)},expression:"blankRationData.GCAA"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}])}),_c('validation-provider',{attrs:{"name":"eca"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',{attrs:{"for":"eca"}},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("eca"))+" (KG) ")]),_c('b-form-input',{attrs:{"id":"eca","placeholder":"300","state":_vm.formValidation(_vm.resetblankration).getValidationState(
            validationContext
          ),"trim":"","type":"number"},model:{value:(_vm.blankRationData.ECA),callback:function ($$v) {_vm.$set(_vm.blankRationData, "ECA", $$v)},expression:"blankRationData.ECA"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}])})],1),_c('b-form-group',{staticClass:"p-1 border d-flex flex-sm-row flex-md-col",attrs:{"id":"environmental-info","label":_vm.$t('environmentalInfo')}},[_c('validation-provider',{attrs:{"name":"sicaklik"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',{attrs:{"for":"sicaklik"}},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("temperature"))+" ")]),_c('b-form-input',{attrs:{"id":"GY","state":_vm.formValidation(_vm.resetblankration).getValidationState(
            validationContext
          ),"trim":"","placeholder":""},model:{value:(_vm.blankRationData.sicaklik),callback:function ($$v) {_vm.$set(_vm.blankRationData, "sicaklik", $$v)},expression:"blankRationData.sicaklik"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}])})],1),_c('b-form-group',{staticClass:"p-1 border d-flex flex-sm-row flex-md-col",attrs:{"id":"min-max-info","label":_vm.$t('roughage') + ' / ' + _vm.$t('grain') + ' ' + _vm.$t('rate') + ' (%)'}},[_c('validation-provider',{attrs:{"name":"minkaba"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"state":_vm.formValidation(_vm.resetblankration).getValidationState(
            validationContext
          ),"label":_vm.$t('minKaba'),"label-for":"minkaba"}},[_c('b-form-input',{attrs:{"id":"sl","state":_vm.formValidation(_vm.resetblankration).getValidationState(
              validationContext
            ),"trim":"","type":"number","placeholder":""},model:{value:(_vm.blankRationData.kabayemorani),callback:function ($$v) {_vm.$set(_vm.blankRationData, "kabayemorani", $$v)},expression:"blankRationData.kabayemorani"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"maxkaba"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"state":_vm.formValidation(_vm.resetblankration).getValidationState(
            validationContext
          ),"label":_vm.$t('maxKaba'),"label-for":"maxkaba"}},[_c('b-form-input',{attrs:{"id":"maxkaba","state":_vm.formValidation(_vm.resetblankration).getValidationState(
              validationContext
            ),"trim":"","type":"number","max":"100","placeholder":""},model:{value:(_vm.blankRationData.kabayemoranimax),callback:function ($$v) {_vm.$set(_vm.blankRationData, "kabayemoranimax", $$v)},expression:"blankRationData.kabayemoranimax"}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }